import { dateToString, SeparatorRow, User_Type } from "../../components/utils";
import React, { useMemo, useEffect, useState } from "react";
import { type MRT_ColumnDef } from "material-react-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

import { fetchGetAllLogs } from "../../services/fetch-admn-api";
import GlobalTable from "../../components/GlobalTable/GlobalTable";
import { formatDiagnosticsWithColorAndContext } from "typescript";

interface IProducts {
  email: string;
  qrCode: string;
  name: string;
  userType: string;
}

export function Logs() {
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {}, []);

  const onChangeDate = (date: Date) => {
    if (!date) {
      setStartDate(new Date());
    }
    setStartDate(date);
  };

  const columns = useMemo<MRT_ColumnDef<IProducts>[]>(
    //column definitions...
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableHiding: true,
        textAlign: "center",
        muiTableHeadCellProps: {
          align: "center",
          variant: "head",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "sku",
        header: "Sku",
        enableHiding: true,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        accessorKey: "error",
        header: "Error",
        textAlign: "center",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        // accessorFn: (originalRow: any) => {
        //   console.log("log", originalRow);
        //   return originalRow.modificado ? originalRow.modificado : "";
        // },
        accessorKey: "modificado",
        // id: "modificado",
        header: "Modificación",
        muiTableHeadCellProps: {
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
        // filterVariant: "date-range",
        // Cell: ({ cell }) => (cell ? cell.getValue<string>() : ""), // convert back to string for display
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row className="justify-content-end  align-items-center">
                  <Col>
                    <CardTitle tag="h4">Logs</CardTitle>
                  </Col>
                  <Col xs="auto"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <DatePicker
                      selected={startDate}
                      onChange={(date: Date) => onChangeDate(date)}
                      showIcon
                      portalId="12"
                      maxDate={new Date()}
                      className={"custom-date-picker"}
                    />
                  </Col>
                </Row>
                <GlobalTable
                  tableColumns={columns}
                  currentFetch={fetchGetAllLogs}
                  params={`startDate=${dateToString(startDate)}`}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
