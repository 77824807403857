import { LoadingOverlay } from "../components/utils";
import React, { useEffect, useState } from "react";
import {
  saveStoreSelected,
  saveUserInformation,
} from "../components/localStorage";
import { fetchGetStores } from "../services/fetch-admn-api";
import { strictEqual } from "assert";
export interface IUserInfo {
  token: string;
  name: string;
  email: string;
  userType: string;
}

export interface IStore {
  idstores: number;
  cod_store: string;
  name: string;
}

export const defaultUser: IUserInfo = {
  token: "",
  name: "",
  email: "",
  userType: "",
};

const defaultContext: UserContextType = {
  isLoading: false,
  isLogin: false,
  showOrHideLoading: () => {
    console.log();
  },
  saveLogin: (user: IUserInfo) => {},
  userInformation: defaultUser,
  stores: [],
  setStoreSelected: (store: IStore) => {},
  onAuthorizationError: () => {},
};

export interface UserContextType {
  showOrHideLoading: (isLoading: boolean) => void;
  isLogin: boolean;
  isLoading: boolean;
  saveLogin: (user: IUserInfo) => void;
  userInformation: IUserInfo;
  stores: IStore[];
  storeSelected?: IStore;
  setStoreSelected: (store: IStore) => void;
  onAuthorizationError: () => void;
}

export const UserContext = React.createContext<UserContextType | null>(
  defaultContext
);

const UserProvider: React.FC<any> = ({ children }: any) => {
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [isLogin, setLogin] = useState(false);
  const [userInformation, setUserInformation] =
    useState<IUserInfo>(defaultUser);
  const [stores, setStores] = useState<IStore[]>([]);
  const [storeSelected, setStoreSelected] = useState<IStore>();

  const showOrHideLoading = (loading: boolean) => {
    setLoading(loading);
  };

  useEffect(() => {
    saveUserInformation.get().then((userInformationSaved: IUserInfo | null) => {
      if (userInformationSaved && userInformationSaved.token === "") {
        setLogin(false);
        setUserInformation(defaultUser);
      } else if (userInformationSaved) {
        setLogin(true);
        setUserInformation(userInformationSaved);
        saveStoreSelected.get().then((storeSelected: IStore | null) => {
          getStores(userInformationSaved.token, storeSelected);
        });
      }
    });
  }, []);

  const saveLogin = (user: IUserInfo) => {
    setLogin(!isLoading);
    if (user && user.token === "") {
      setLogin(false);
    } else if (user) {
      setLogin(true);
    }
    setUserInformation(user);
    saveUserInformation.set(user);
  };

  const onAuthorizationError = () => {
    setLoading(true);
    setLogin(false);
    setUserInformation(defaultUser);
    saveUserInformation.set(defaultUser);
    setTimeout(() => {
      setLoading(false);
      window.location.href = "/login";
    });
  };

  const getStores = async (token: string, storeSelected: IStore | null) => {
    const stores = await fetchGetStores(token, onAuthorizationError);
    setStores(stores);
    if (storeSelected) {
      setStoreSelected(storeSelected);
    } else if (stores && stores.length > 0) {
      setStoreSelected(stores[0]);
      saveStoreSelected.set(stores[0]);
    }
  };

  return (
    <UserContext.Provider
      value={{
        showOrHideLoading,
        isLogin,
        isLoading,
        saveLogin,
        userInformation,
        stores,
        storeSelected,
        setStoreSelected,
        onAuthorizationError,
      }}
    >
      <LoadingOverlay isLoading={isLoading} />
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
