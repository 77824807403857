import { SeparatorRow, User_Type } from "../../components/utils";
import React, { useEffect, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  type MRT_Virtualizer,
} from "material-react-table";
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { UserContext } from "../../context/userContext";
import { UserContextType } from "../../context/userContext";
import { fetchGetPendingProducts } from "../../services/fetch-admn-api";
import GlobalTable from "../../components/GlobalTable/GlobalTable";

interface IPendingProduct {
  email: string;
  qrCode: string;
  name: string;
  userType: string;
}
const columns: MRT_ColumnDef<IPendingProduct>[] = [
  {
    accessorKey: "sku",
    header: "Sku",
  },
  {
    accessorKey: "codebars",
    header: "Cod. Bar",
  },
  {
    accessorKey: "body_html",
    header: "Body",
  },
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "product_type",
    header: "Tipo de producto",
  },
  {
    accessorKey: "coste",
    header: "Costo",
  },
  {
    accessorKey: "price",
    header: "Precio",
  },
  {
    accessorKey: "talla",
    header: "Talla",
  },
  {
    accessorKey: "idshopify",
    header: "Id Shopify",
  },
  {
    accessorKey: "idvariant",
    header: "Id Variante",
  },
  {
    accessorKey: "sub_cat1",
    header: "Sub categrotia 1",
  },
  {
    accessorKey: "sub_cat2",
    header: "Sub categrotia 2",
  },
  {
    accessorKey: "sub_cat3",
    header: "Sub categrotia 3",
  },
  {
    accessorKey: "sub_cat4",
    header: "Sub categrotia 4",
  },
  {
    accessorKey: "tags",
    header: "Tags",
  },
  {
    accessorKey: "meta_relacion",
    header: "Meta relación",
  },
  {
    accessorKey: "status",
    header: "Status",
  },
];
export function PendingProduct() {
  const { showOrHideLoading, userInformation, onAuthorizationError } =
    React.useContext(UserContext) as UserContextType;
  const [pendingProducts, setpendingProducts] = useState<IPendingProduct[]>([]);
  const [pendingProductselected, setpendingProductselected] = useState<
    IPendingProduct | undefined
  >(undefined);

  useEffect(() => {}, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row className="justify-content-end  align-items-center">
                  <Col>
                    <CardTitle tag="h4">Productos pendientes</CardTitle>
                  </Col>
                  <Col xs="auto"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <GlobalTable
                  tableColumns={columns}
                  currentFetch={fetchGetPendingProducts}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
