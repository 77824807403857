import { SeparatorRow, User_Type } from "../../components/utils";
import React, { useMemo, useEffect, useState } from "react";
import { type MRT_ColumnDef } from "material-react-table";
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";

import { fetchGetAllProducts } from "../../services/fetch-admn-api";
import GlobalTable from "../../components/GlobalTable/GlobalTable";

interface IProducts {
  email: string;
  qrCode: string;
  name: string;
  userType: string;
}

export function Products() {
  const [products, setproducts] = useState<IProducts[]>([]);
  const [productselected, setproductselected] = useState<IProducts | undefined>(
    undefined
  );

  useEffect(() => {}, []);

  const columns = useMemo<MRT_ColumnDef<IProducts>[]>(
    //column definitions...
    () => [
      {
        accessorKey: "sku",
        header: "Sku",
        enableHiding: true,
      },
      {
        accessorKey: "codebars",
        header: "Cod. Bar",
      },
      {
        accessorKey: "body_html",
        header: "Body",
      },
      {
        accessorKey: "title",
        header: "Title",
      },
      {
        accessorKey: "product_type",
        header: "Tipo de producto",
      },
      {
        accessorKey: "coste",
        header: "Costo",
      },
      {
        accessorKey: "price",
        header: "Precio",
      },
      {
        accessorKey: "talla",
        header: "Talla",
      },
      {
        accessorKey: "idshopify",
        header: "Id Shopify",
      },
      {
        accessorKey: "idvariant",
        header: "Id Variante",
      },
      {
        accessorKey: "sub_cat1",
        header: "Sub categrotia 1",
      },
      {
        accessorKey: "sub_cat2",
        header: "Sub categrotia 2",
      },
      {
        accessorKey: "sub_cat3",
        header: "Sub categrotia 3",
      },
      {
        accessorKey: "sub_cat4",
        header: "Sub categrotia 4",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
    ],
    []
  );

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row className="justify-content-end  align-items-center">
                  <Col>
                    <CardTitle tag="h4">Productos</CardTitle>
                  </Col>
                  <Col xs="auto"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <GlobalTable
                  tableColumns={columns}
                  currentFetch={fetchGetAllProducts}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
