import { SeparatorRow, User_Type } from "../../components/utils";
import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";

import {
  fetchGetAllTasks,
  fetchStartTask,
} from "../../services/fetch-admn-api";
import { UserContext, UserContextType } from "../../context/userContext";
import { saveStoreSelected } from "../../components/localStorage";
import cronstrue from "cronstrue/i18n";

interface IProps {
  isOpen: boolean;
  onAction: () => void;
  onClose: () => void;
  taskName: string;
  message: string;
}
function WarningModal({
  onAction,
  isOpen,
  onClose,
  taskName,
  message,
}: IProps) {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <SeparatorRow height={40} />
        <Row className="justify-content-center">
          <Col xs="auto">
            <svg
              fill="#003964"
              width="80px"
              height="80px"
              viewBox="0 -8 528 528"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>info</title>
              <path d="M264 456Q210 456 164 429 118 402 91 356 64 310 64 256 64 202 91 156 118 110 164 83 210 56 264 56 318 56 364 83 410 110 437 156 464 202 464 256 464 310 437 356 410 402 364 429 318 456 264 456ZM296 208L296 144 232 144 232 208 296 208ZM296 368L296 240 232 240 232 368 296 368Z" />
            </svg>
          </Col>
        </Row>
        <SeparatorRow height={50} />
        <Row className="justify-content-center">
          <Col xs="8" className="text-center">
            <span className="message-warning">
              Esta seguro que desea iniciar la tare <b>{taskName}</b>
            </span>
          </Col>
        </Row>
        <SeparatorRow height={50} />
        {message.length > 0 && (
          <Row className="justify-content-center my-2">
            <Col xs="auto">{message}</Col>
          </Row>
        )}
        <Row className="justify-content-center">
          <Col xs="auto">
            <button
              type="button"
              className="btn-cancel remove"
              onClick={onClose}
            >
              Cancelar
            </button>
          </Col>
          <Col xs="auto">
            <button
              type="button"
              onClick={onAction}
              className="btn-confirm-remove"
            >
              Iniciar
            </button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
}

interface ITask {
  id: number;
  name_task: string;
  crondate: string;
  ejecutando: string;
  lastupdate: string;
}

export function Tasks() {
  const [taskLsit, setTaskList] = useState<ITask[]>([]);
  const [taskSelected, setTaskSelected] = useState<ITask>();
  const { showOrHideLoading, userInformation, onAuthorizationError } =
    React.useContext(UserContext) as UserContextType;
  const [isOpenWarningModal, setOpenWarnignModal] = useState(false);
  const [message, setMessage] = useState("");
  const [alreadySearch, setAlreadySearch] = useState(false);
  useEffect(() => {
    getTaskList();
  }, []);

  const getTaskList = async () => {
    const store = await saveStoreSelected.get();
    showOrHideLoading(true);
    const taskList = await fetchGetAllTasks(
      userInformation.token,
      onAuthorizationError,
      store!.cod_store
    );
    setTaskList(taskList);
    setAlreadySearch(true);
    showOrHideLoading(false);
  };

  const handleSelectStore = async (task: ITask) => {
    setTaskSelected(task);
    setOpenWarnignModal(true);
  };

  const handleStartTask = async () => {
    showOrHideLoading(true);

    const store = await saveStoreSelected.get();
    const response = await fetchStartTask(
      {
        token: userInformation.token,
        name: taskSelected!.name_task,
        store: store!.cod_store,
      },
      onAuthorizationError
    );
    if (response.success) {
      setMessage(response.message);
      setTimeout(() => {
        setMessage("");
        showOrHideLoading(false);
        setOpenWarnignModal(false);
      }, 3000);
    } else {
      setOpenWarnignModal(false);
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row className="justify-content-end  align-items-center">
                  <Col>
                    <CardTitle tag="h4">Tareas</CardTitle>
                  </Col>
                  <Col xs="auto"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="justify-content-center">
                  <Col xs="10">
                    <Row className="align-items-center my-2 py-2 header-row">
                      <Col className="text-center">
                        <b>Nombre</b>
                      </Col>
                      <Col className="text-center">
                        <b>Estado</b>
                      </Col>
                      <Col className="text-center">
                        <b>Descripción</b>
                      </Col>
                      <Col className="text-center">
                        <b>Última actualización</b>
                      </Col>
                      <Col></Col>
                    </Row>
                    {alreadySearch && taskLsit.length === 0 && (
                      <Row className="justify-content-center">
                        <Col xs="auto">
                          <p className="empty-results">
                            No hay registros para mostrar
                          </p>
                        </Col>
                      </Row>
                    )}
                    {taskLsit.map(
                      (
                        { name_task, ejecutando, lastupdate, crondate, id },
                        index
                      ) => {
                        return (
                          <Row
                            className="align-items-center my-2 py-2 border-bottom"
                            key={index}
                          >
                            <Col className="text-center">{name_task}</Col>
                            <Col className="text-center">
                              {parseInt(ejecutando) === 0
                                ? "Apagado"
                                : "Iniciado"}
                            </Col>
                            <Col className="text-center">
                              {cronstrue.toString(crondate, { locale: "es" })}
                            </Col>
                            <Col className="text-center">
                              {new Date(lastupdate).toDateString()}
                            </Col>
                            <Col className="text-center">
                              {parseInt(ejecutando) === 0 && (
                                <button
                                  className="bg-ransparent btn-start"
                                  type="button"
                                  onClick={() =>
                                    handleSelectStore({
                                      name_task,
                                      ejecutando,
                                      lastupdate,
                                      crondate,
                                      id,
                                    })
                                  }
                                >
                                  <svg
                                    fill="#ccc"
                                    width="20px"
                                    height="20px"
                                    viewBox="-4 -3 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    preserveAspectRatio="xMinYMin"
                                    className="jam jam-play"
                                  >
                                    <path d="M13.82 9.523a.976.976 0 0 0-.324-1.363L3.574 2.128a1.031 1.031 0 0 0-.535-.149c-.56 0-1.013.443-1.013.99V15.03c0 .185.053.366.153.523.296.464.92.606 1.395.317l9.922-6.031c.131-.08.243-.189.325-.317zm.746 1.997l-9.921 6.031c-1.425.867-3.3.44-4.186-.951A2.918 2.918 0 0 1 0 15.03V2.97C0 1.329 1.36 0 3.04 0c.567 0 1.123.155 1.605.448l9.921 6.032c1.425.866 1.862 2.696.975 4.088-.246.386-.58.712-.975.952z" />
                                  </svg>
                                  Iniciar
                                </button>
                              )}
                            </Col>
                          </Row>
                        );
                      }
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <WarningModal
        isOpen={isOpenWarningModal}
        onAction={handleStartTask}
        onClose={() => setOpenWarnignModal(!isOpenWarningModal)}
        taskName={taskSelected ? taskSelected.name_task : ""}
        message={message}
      />
    </>
  );
}
